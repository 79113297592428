<template>
    <router-link :to="community.url" :target="width < 768 ? '_self' : '_blank'" @click="track('店頭-看物件', community.title)">
        <div class="list-content-parent" :style="showItem.length == 0 || showItem.indexOf(community.id) != -1 ? '' : 'display: none;'">
            <div class="thumbnail-two d-flex d-md-none justify-content-center">
                <div class="img" v-for="(img, index) in community.img">
                    <img :src="img" :alt="community.title" class="w-100">
                </div>
            </div>
            <div :class="'list-content community' + (community.id == markerChecked ? ' checked' : '')">
                <div class="list-body">
                    <div class="thumbnail d-none d-md-block">
                        <img :src="community.img[0]" :alt="community.title" class="w-100" v-if="community.img[0] != ''">
                    </div>
                    <div class="meta">
                        <div class="list-heading">
                            <h3 class="title text-truncate">{{ community.title }}</h3>
                            <div class="sale-tag">{{ community.salesItem }} <span class="small">筆待售</span></div>
                        </div>
                        <div class="structure d-flex align-items-center">
                            <span class="me-3">{{ community.household }}戶</span>
                            <span>{{ community.age }}年</span>
                        </div>
                        <div class="info-group d-flex align-items-center">
                            <span class="me-3">{{ community.ping }}坪</span>
                            <span>{{ community.floor }}F</span>
                        </div>
                    </div>
                </div>
                <div class="list-footer text-truncate">
                    <IconPin class="icon d-inline-block me-2"/>
                    <span class="address">{{ community.address }}</span>
                </div>
                <div class="list-above">
                    <div class="box ps-md-2 ps-4">
                        <div><span class="value red">{{ community.closingPrice }}</span><span class="unit ms-1">萬/坪</span></div>
                        <small>最新成交價</small>
                    </div>
                    <div class="box">
                        <div><span class="value">{{ community.yearClinch }}</span><span class="unit ms-1">筆</span></div>
                        <small>近一年成交數</small>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'CommunityMapList',
    data() {
        return {
            width: 0
        };
    },
    props: {
        community: Object,
        markerChecked: Number,
        showItem: Array,
    },
    components: {
        IconPin,
    },
    methods: {
        track: function (type, typeName) {
            return false;
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
        });
    }
}
</script>

