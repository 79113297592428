<template>
    <div class="card-product">
        <a href="#" target="_blank" rel="noopener noreferrer">
            <div class="img">
                <div :class="this.nametop && this.nametop +' name'" v-if="this.nametop">世貿新城-甲乙丙丁戊</div>
                <div class="badge badge-type">電梯大樓</div>
                <div class="badge badge-view">全景</div>
                <div class="img-ratio-house">
                    <img src="https://s3-us-west-2.amazonaws.com/ibigfun/house/img/8800000245/image%20(12)20210630170219.jpg" alt="" class="w-100">
                </div>
            </div>
            <div class="card-body">
                <h3>達永沐夏全新2房車位</h3>
                <p class="structure">2房2廳1衛1陽 </p>
                <div class="info-group d-flex align-items-center">
                    <div>
                        <span class="me-2">45.01坪</span>
                        <span>16年</span>
                    </div>
                    <div class="price ms-auto">3589 萬</div>
                </div>
            </div>
            <div class="card-footer pt-0">
                <p class="address">
                    <IconPin class="icon"/>
                    新北市板橋區文化路一段
                </p>
            </div>
        </a>
    </div>
</template>
<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'CommunityCard',
    props: {
        nametop: String,
        url: String,
        houseType: String,
        total: Number,
        img: String,
        subject: String,
        address: String,
        info: Array,
        about: Object,
        vrLink: String,
        siteName: String,
        ping: String,
        age: String,
        pattern: String,
    },
    components: {
        IconPin
    },
    methods: {

    },
}
</script>

