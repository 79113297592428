<template>
    <svg width="10.74" height="12.8" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.77696 11.8001V12.3001H10.277C10.4052 12.3001 10.5444 12.4146 10.5444 12.6001C10.5444 12.7856 10.4052 12.9001 10.277 12.9001H8.20737C7.93122 12.9001 7.70737 12.6762 7.70737 12.4001V11.0001C7.70737 10.3019 7.15933 9.7001 6.43997 9.7001H4.90517C4.1858 9.7001 3.63777 10.3019 3.63777 11.0001V12.4001C3.63777 12.6762 3.41391 12.9001 3.13777 12.9001H1.06818C0.939902 12.9001 0.800781 12.7856 0.800781 12.6001C0.800781 12.4146 0.939902 12.3001 1.06818 12.3001H1.56818V11.8001V2.2001C1.56818 1.57277 2.05088 1.1001 2.60298 1.1001H8.74216C9.29426 1.1001 9.77696 1.57277 9.77696 2.2001V11.8001ZM3.37037 2.5001H2.87037V3.0001V4.6001V5.1001H3.37037H4.90517H5.40517V4.6001V3.0001V2.5001H4.90517H3.37037ZM5.40517 6.2001V5.7001H4.90517H3.37037H2.87037V6.2001V7.8001V8.3001H3.37037H4.90517H5.40517V7.8001V6.2001ZM6.43997 2.5001H5.93997V3.0001V4.6001V5.1001H6.43997H7.97476H8.47476V4.6001V3.0001V2.5001H7.97476H6.43997ZM8.47476 6.2001V5.7001H7.97476H6.43997H5.93997V6.2001V7.8001V8.3001H6.43997H7.97476H8.47476V7.8001V6.2001Z" fill="#DD4C27" stroke="#DD4C27"/>
    </svg>
</template>

<script>
export default {
  name: 'IconBuilding'
}
</script>

