<template>
    <div class="real-price-page">
        <section class="page-kv">
            <!-- 桌機圖 -->
            <img :src="image.bannerPc" :alt="meta.alt.alt_8_1" class="w-100 d-none d-md-inline-block"
                 v-if="image.bannerPc != undefined && image.bannerPc != ''">
            <!-- 手機圖 -->
            <img :src="image.bannerMobile" :alt="meta.alt.alt_8_1" class="w-100 d-inline-block d-md-none"
                 v-if="image.bannerMobile != undefined && image.bannerMobile != ''">
        </section>
        <div class="collection-search d-none d-md-block mt-negative bg-wh">
            <div class="container">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">首頁</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">實價登錄</li>
                    </ol>
                </nav>
                <div class="search-panel" v-if="width > 767">
                    <div class="searchTab-content tab-content">
                        <div class="" id="searchAll" role="tabpanel">
                            <div class="search-form-wrapper">
                                <div class="form-group form-search-group">
                                    <label class="visually-hidden" for="searchInput">關鍵字搜尋</label>
                                    <i class="icon">
                                        <IconSearch/>
                                    </i>
                                    <input type="text" class="form-control rounded-input" id="searchInput" placeholder="請輸入物件編號、物件名稱、路名、社區名稱"
                                           @keyup="changeChecked()" v-model="checkedList.keyword">
                                </div>

                                <div class="row g-2 mb-2">
                                    <div class="form-group dropdown-wrapper col-auto dropdown-1">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(1)">
                                            <div :class="'btn btn-select' + (checkedList.checkedCity != 0 ? ' selected' : '')">
                                                地區
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div :class="'select-dropdown region_dropdown p-0' + (townList.length > 0 ? ' show_result' : '')">
                                            <div class='region_dropdown-row'>
                                                <ul id="accordionRegion" class="accordion_region accordion accordion-flush region-list">
                                                    <template v-for="(regionName, regionIndex) in regionList">
                                                        <li class="item" role="presentation" v-if="cityList[regionIndex].length > 0">
                                                            <button type="button" :class="'nav-link' + (regionIndex == 0 ? ' active' : '')"
                                                                    id="north-tab" :region="regionIndex" data-bs-toggle="collapse" :data-bs-target="'#region-' + regionIndex"
                                                                    :aria-expanded="regionIndex == 0 ? 'true' : 'false'" aria-controls="north"
                                                                    :aria-selected="regionIndex == 0 ? 'true' : 'false'">
                                                                {{ regionName }}
                                                                <i class="icon">
                                                                    <IconSortDown class="down"/>
                                                                    <IconSortUp class="up"/>
                                                                </i>
                                                            </button>
                                                            <div :id="'region-' + regionIndex" :class="'accordion-collapse collapse' + (regionIndex == 0 ? ' show' : '')"
                                                                 data-bs-parent="#accordionRegion">
                                                                <div class="accordion-body pt-1">
                                                                    <div class="row gx-3 gy-2">
                                                                        <div class="col-6" v-for="(city, index) in cityList[regionIndex]">
                                                                            <button :class="'btn btn-checkbox w-100 ' + city.active + city.selected"
                                                                                    data-name="city"
                                                                                    :id="'city-' + regionIndex +'-' + index" @click="getTown(city)">
                                                                                {{ city.city }}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                                <div class="result_region" v-if="townList.length > 0">
                                                    <hr>
                                                    <div class="row g-1">
                                                        <div class="col-4" v-for="(town, index) in townList">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="town" :id="'town-' + index" :value="town"
                                                                       @click="changeTown();" v-model="checkedList.checkedTown">
                                                                <label class="form-check-label" :for="'town-' + index">{{ town }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(2)">
                                            <div :class="checkedList.checkedHouseType.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                型態
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="form-content">
                                                <div class="row g-1">
                                                    <div class="col-4" v-for="(houseType, index) in houseTypeList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="houseType[]" :id="'houseType-' + index"
                                                                   :value="index + 1" v-model="checkedList.checkedHouseType" @click="changeChecked">
                                                            <label class="form-check-label" :for="'houseType-' + index">{{ houseType }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-3">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(3)">
                                            <div :class="checkedList.checkedTransactionPeriod.length > 0 ? 'btn btn-select selected' : 'btn btn-select'">
                                                成交期間
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-6" v-for="(transactionPeriod, index) in transactionPeriodList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="transactionPeriod[]" :id="'transactionPeriod-' + index"
                                                               :value="index + 1" @click="changeChecked()" v-model="checkedList.checkedTransactionPeriod">
                                                        <label class="form-check-label" :for="'transactionPeriod-' + index">{{ transactionPeriod }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="transactionPeriod-5" value="6"
                                                                   v-model="checkedList.checkedTransactionPeriod">
                                                            <label class="form-check-label" for="transactionPeriod-5"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('transactionPeriod')"
                                                                   @keyup="changeChecked()" v-model="checkedList.transactionPeriodS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('transactionPeriod')"
                                                                   @keyup="changeChecked()" v-model="checkedList.transactionPeriodE">
                                                        </div>
                                                        <span class="px-2">年</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-4">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(4)">
                                            <div :class="checkedList.checkedPrice.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                總價
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-6" v-for="(price, index) in priceList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index"
                                                               :value="index + 1" @click="changeChecked()" v-model="checkedList.checkedPrice">
                                                        <label class="form-check-label" :for="'price-' + index">{{ price }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="price-9" value="9" v-model="checkedList.checkedPrice">
                                                            <label class="form-check-label" for="price-9"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                                   @keyup="changeChecked()" v-model="checkedList.priceS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                                   @keyup="changeChecked()" v-model="checkedList.priceE">
                                                        </div>
                                                        <span class="px-2">萬</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-5">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(5)">
                                            <div :class="checkedList.checkedAge.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                屋齡
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-4" v-for="(age, index) in ageList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1"
                                                               @click="changeChecked()" v-model="checkedList.checkedAge">
                                                        <label class="form-check-label" :for="'age-' + index">{{ age }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="age-6" value="6"
                                                                   v-model="checkedList.checkedAge">
                                                            <label class="form-check-label" for="age-6"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                                   @keyup="changeChecked()" v-model="checkedList.ageS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                                   @keyup="changeChecked()" v-model="checkedList.ageE">
                                                        </div>
                                                        <span class="px-2">年</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-6">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(6)">
                                            <div :class="checkedList.checkedRoom.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                格局
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-3" v-for="(room, index) in roomList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="room" :id="'room-' + index"
                                                               :value="index + 1" @click="changeChecked()" v-model="checkedList.checkedRoom">
                                                        <label class="form-check-label" :for="'room-' + index">{{ room }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="room-5" value="5" v-model="checkedList.checkedRoom">
                                                            <label class="form-check-label" for="room-5"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                                   @keyup="changeChecked()" v-model="checkedList.roomS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                                   @keyup="changeChecked()" v-model="checkedList.roomE">
                                                        </div>
                                                        <span class="px-2">房</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-7">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(7)">
                                            <div :class="
                                                    checkedList.checkedFloor.length > 0 ||
                                                    checkedList.checkedAveragePrice.length > 0 ||
                                                    checkedList.checkedBuildingPing.length > 0 ||
                                                    checkedList.checkedLandPing.length > 0 ||
                                                    checkedList.checkedParking !== 0
                                                    ? 'btn btn-select selected' : 'btn btn-select'">
                                                進階搜尋
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="advanced-search-form">
                                                <h3>單價</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced3">
                                                    <div class="col" v-for="(averagePrice, index) in averagePriceList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'averagePrice-' + index"
                                                                   :value="index + 1" @click="changeChecked()" v-model="checkedList.checkedAveragePrice">
                                                            <label class="form-check-label" :for="'averagePrice-' + index">{{ averagePrice }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="averagePrice-5" value="6"
                                                                       v-model="checkedList.checkedAveragePrice">
                                                                <label class="form-check-label" for="averagePrice-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('averagePrice')"
                                                                           @keyup="changeChecked()" v-model="checkedList.averagePriceS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('averagePrice')"
                                                                           @keyup="changeChecked()" v-model="checkedList.averagePriceE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>樓層</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced2">
                                                    <div class="col" v-for="(floor, index) in floorList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'floor-' + index" :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedFloor">
                                                            <label class="form-check-label" :for="'floor-' + index">{{ floor }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other-input-group d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="floor-4" value="5" v-model="checkedList.checkedFloor">
                                                                <label class="form-check-label" for="floor-4"></label>
                                                            </div>
                                                            <div class="start">
                                                                <input type="text" class="form-control form-control-sm"
                                                                       @focus="checkFocusOther('floor')" @keyup="changeChecked()" v-model="checkedList.floorS">
                                                            </div>
                                                            <span class="px-2">-</span>
                                                            <div class="end">
                                                                <input type="text" class="form-control form-control-sm"
                                                                       @focus="checkFocusOther('floor')" @keyup="changeChecked()" v-model="checkedList.floorE">
                                                            </div>
                                                            <span class="px-2">樓</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>建坪</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced4">
                                                    <div class="col" v-for="(buildingPing, index) in buildingPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'buildingPing-' + index"
                                                                   :value="index + 1" @click="changeChecked()" v-model="checkedList.checkedBuildingPing">
                                                            <label class="form-check-label" :for="'buildingPing-' + index">{{ buildingPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="buildingPing-5" value="6"
                                                                       v-model="checkedList.checkedBuildingPing">
                                                                <label class="form-check-label" for="buildingPing-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('buildingPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.buildingPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('buildingPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.buildingPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>地坪</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced5">
                                                    <div class="col" v-for="(landPing, index) in landPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'landPing-' + index" :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedLandPing">
                                                            <label class="form-check-label" :for="'landPing-' + index">{{ landPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="landPing-5" value="6" v-model="checkedList.checkedLandPing">
                                                                <label class="form-check-label" for="landPing-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.landPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.landPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>車位</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2" id="advanced6">
                                                    <div class="col" v-for="(parking, index) in parkingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" :id="'parking-' + index" :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedParking">
                                                            <label class="form-check-label" :for="'parking-' + index">{{ parking }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-tags pt-3">
                                    <div class="d-flex align-items-center mb-2">
                                        <h4 class="title me-2 mb-0">已選條件({{ searchTagList.length }})</h4>
                                        <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                            <span class="text">清除全部</span>
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                                      fill="#999999"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="result-tags">
                                        <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                            {{ tag.value }}
                                            <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                                <IconClose/>
                                            </i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="collection-search-mobile" v-if="width < 768">
            <div class="container" id="mobileSearchContainer">
                <div class="search-group">
                    <div class="form-group form-search-group mb-3">
                        <i class="icon">
                            <IconSearch/>
                        </i>
                        <input type="text" class="form-control search-input" placeholder="請輸入物件編號、物件名稱、路名..." @keyup="changeChecked()"
                               v-model="checkedList.keyword">
                    </div>
                    <div class="btn-wrapper">
                        <button type="button" class="btn btn-primary w-100 big-r" data-bs-toggle="modal" data-bs-target="#mobileSearchModal">篩選條件
                        </button>
                    </div>
                    <hr class="mb-0 mt-3 search-tags-hr">
                    <div class="search-tags pt-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <h4 class="title mb-0">已選條件({{ searchTagList.length }})</h4>
                                <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                    <span class="text">清除全部</span>
                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                              fill="#999999"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="ml-auto btn btn-collapse" v-if="searchTagList.length > 0" data-bs-toggle="collapse" href="#mobileResultTags"
                                 role="button"
                                 aria-expanded="true" aria-controls="mobileResultTags">
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </div>
                        </div>
                        <div class="result-tags collapse show" id="mobileResultTags">
                            <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                {{ tag.value }}
                                <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                    <IconClose/>
                                </i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="actual-chart qaslow">
            <div class="container">
                <p class="d-flex">
                    行情走勢
                    <i class="icon ms-1 text-primary">
                        <IconInfo/>
                    </i>
                </p>
                <div class="redline-heading d-flex justify-content-between align-items-center mb-4">
                    <h2 v-if="checkedList.checkedTown != ''">{{ checkedList.checkedCity }} <span class="mx-2">・</span> {{ checkedList.checkedTown }}</h2>
                    <h2 v-else>台北市 <span class="mx-2">・</span> 中正區</h2>
                    <div class="rg-links d-none d-sm-block">
                        <a href="javascript:;" :class="checkedRange == 1 ? 'active' : ''" @click="changeRange(1)">3個月內</a>
                        <a href="javascript:;" :class="checkedRange == 2 ? 'active' : ''" @click="changeRange(2)">半年內</a>
                        <a href="javascript:;" :class="checkedRange == 3 ? 'active' : ''" @click="changeRange(3)">一年內</a>
                    </div>
                </div>
                <div class="rg-links d-block d-sm-none my-3 text-center">
                    <a href="javascript:;" :class="checkedRange == 1 ? 'active' : ''" @click="changeRange(1)">3個月內</a>
                    <a href="javascript:;" :class="checkedRange == 2 ? 'active' : ''" @click="changeRange(2)">半年內</a>
                    <a href="javascript:;" :class="checkedRange == 3 ? 'active' : ''" @click="changeRange(3)">一年內</a>
                </div>
                <div class="catetab-wrapper d-none d-sm-flex px-md-4">
                    <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 0 ? ' active' : '')" @click="changeCategory(0)">全部</button>
                    <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 1 ? ' active' : '')" @click="changeCategory(1)">公寓</button>
                    <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 2 ? ' active' : '')" @click="changeCategory(2)">大樓</button>
                    <button type="button" :class="'btn btn-catetab' + (checkedCategory == 3 ? ' active' : '')" @click="changeCategory(3)">透天</button>
                </div>
                <div class="charts-top-status kseiwwsj d-md-flex  align-items-baseline justify-content-between mb-4 my-2 px-md-4">
                    <div class="date">{{ statistics.information[checkedRange].monthS }} ~ {{ statistics.information[checkedRange].monthE }}</div>
                    <div class="info">
                        <div><span class="d-block d-md-inline-block">平均單價</span><b
                            class="text-red mx-2">{{ statistics.average[checkedRange][checkedCategory] }}</b>萬/坪
                        </div>
                        <div class="ms-md-3"><span class="d-block d-md-inline-block">成交件數</span><b
                            class="text-red mx-2">{{ statistics.count[checkedRange][checkedCategory] }}</b>件
                        </div>
                    </div>
                </div>
                <div class="chart-content">
                    <div class="w-100">
                        <div ref="chart" id="chartMain" class="chart_div" style="height:345px;"></div>
                    </div>
                    <div class="catetab-wrapper d-flex d-sm-none px-md-4">
                        <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 0 ? ' active' : '')" @click="changeCategory(0)">全部</button>
                        <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 1 ? ' active' : '')" @click="changeCategory(1)">公寓</button>
                        <button type="button" :class="'btn btn-catetab me-2' + (checkedCategory == 2 ? ' active' : '')" @click="changeCategory(2)">大樓</button>
                        <button type="button" :class="'btn btn-catetab' + (checkedCategory == 3 ? ' active' : '')" @click="changeCategory(3)">透天</button>
                    </div>
                </div>
            </div>
        </section>
        <section class="house-actual collections">
            <div class="container">
                <!--                <div class="actual-table-wrapper mb-4">-->
                <div class="actual-table-wrapper">
                    <div class="d-flex mb-3">
                        <div class="">
                            <p class="tt2 mb-0">
                                <span class="d-block d-sm-inline-block" v-if="mode == 1">地圖可視範圍</span>
                                <span v-if="mode == 1">共 </span>
                                <span v-if="mode == 2">共有</span>
                                <span class="text-danger">{{ totalRecord }}</span>
                                筆實價登錄
                                <i class="icon ms-1 text-primary" data-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="・公寓 - 5樓含以下無電梯<br>・華廈 - 10樓含以下有電梯<br>・住宅大樓 - 11樓含以上有電梯">
                                    <IconInfo/>
                                </i>
                            </p>
                        </div>

                        <div class="ms-auto">
                            <div class="view-switch ms-auto mb-0">
                                <button @click="changeMode(1)" :class="mode == 1 ? 'btn btn-link active' : 'btn btn-link'">
                                    <IconMap class="me-0 me-md-2"/>
                                    <span class="text d-none d-md-inline-block">地圖<span class="d-none d-lg-inline-block">模式</span></span>
                                </button>
                                <button @click="changeMode(2)" :class="mode == 2 ? 'btn btn-link sm-border active' : 'btn btn-link sm-border'">
                                    <IconGrid class="me-0 me-md-2"/>
                                    <span class="text d-none d-md-inline-block">列表<span class="d-none d-lg-inline-block">模式</span></span>
                                </button>
                                <div class="dropdown ms-lg-3">
                                    <button class="btn btn-link d-block d-lg-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <IconSort/>
                                    </button>
                                    <ul class="dropdown-menu select-dropdown" aria-labelledby="dropdownSort">
                                        <li><a :class="'dropdown-item' + (item.orderBy == checkedList.orderBy && item.sort == checkedList.sort ? ' active' : '')"
                                               href="javascript:;" v-for="(item, index) in orderByList" @click="changeOrderBy(index)">{{ item.name }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 地圖模式 -->
                    <div :class="'map-view colleciotn-map-view community' + (mode == 1 ? '' : ' d-none')">
                        <div class="map-content">
                            <div id="map"></div>
                        </div>
                        <div class="map-list-group community-group wiqoeuu" id="communityWiqoeuu" v-if="width >= 992">
                            <div class="real-price-map-list">
                                <RealPriceMap :house="house" :markerChecked="markerChecked" :showItem="checkedList.showItem" v-for="house in houseList"></RealPriceMap>
                            </div>
                        </div>
                        <div class="map-list-group community-group wiqoeuu" id="communityWiqoeuu" v-if="width < 992">
                            <RealPriceMap :house="house" :markerChecked="markerChecked" :showItem="checkedList.showItem" v-for="house in houseList"></RealPriceMap>
                        </div>
                    </div>
                    <!-- 列表模式 -->
                    <div class="community-lists-view real-price-list" v-if="mode == 2">
                        <div class="d-none d-lg-block">
                            <table class="actual-table table bg-white">
                                <thead>
                                <tr>
                                    <th class="text-center">年月 <i class="icon" @click="realPriceSort('transactionDate')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">地址</th>
                                    <th class="text-center">型態</th>
                                    <th class="text-center">總價 <i class="icon" @click="realPriceSort('totalPrice')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">單價 <i class="icon" @click="realPriceSort('averagePrice')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">總坪 <i class="icon" @click="realPriceSort('totalPing')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">地坪 <i class="icon" @click="realPriceSort('landPing')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">屋齡 <i class="icon" @click="realPriceSort('age')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center">樓層</th>
                                    <th class="text-center">格局 <i class="icon" @click="realPriceSort('pattern')">
                                        <IconSort/>
                                    </i></th>
                                    <th class="text-center"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="house in houseList">
                                    <td>{{ house.transactionDate }}</td>
                                    <td>
                                        <div class="address-group">
                                            <img src="../assets/images/group-3337.png" alt="" class="group-3337" v-if="house.img == 0">
                                            <img :src="house.img[0]" alt="" class="group-3337" v-else>
                                            <div class="text-start">
                                                <a :href="house.communityUrl" target="_blank" v-if="house.communityUrl != ''">
                                                    <div class="btn btn-smtag mb-1" v-if="house.communityName != ''">
                                                        {{ house.communityName }}
                                                    </div>
                                                </a>
                                                <div class="btn btn-smtag mb-1" v-else-if="house.communityName != ''">
                                                    {{ house.communityName }}
                                                </div>
                                                <div>{{ house.address }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ house.buildingType }}</td>
                                    <td><b class="text-danger">{{ house.totalPrice }}萬</b></td>
                                    <td>{{ house.averagePrice }}萬</td>
                                    <td>{{ house.totalPing }}坪</td>
                                    <td>{{ house.landPing }}坪</td>
                                    <td>{{ house.age }}年</td>
                                    <td>{{ house.floor }}</td>
                                    <td>{{ house.pattern }}</td>
                                    <td>
                                        <div class="tag-lbgreen" v-if="house.category == 2">內政部</div>
                                        <div>
                                            <a href="#" class="link-red_uii" data-bs-toggle="modal" :data-bs-target="house.category == 2 ? '#actualModal2' : '#actualModal'"
                                               @click="showModal(house)">完整資訊</a>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-block d-lg-none">
                            <div class="actual-tbcard" v-for="house in houseList">
                                <h3 class="title">{{ house.subject }}</h3>
                                <div class="d-block d-md-flex align-items-start">
                                    <div class="info-container">
                                        <div class="infos-group">
                                            <p class="infos">
                                                <span class="me-3" v-if="house.age != -1">{{ house.age }}年</span>
                                                <span class="me-3">{{ house.floor }}樓</span>
                                                <span class="">{{ house.buildingType }}</span>
                                            </p>
                                            <p class="infos">
                                                <span class="me-3">總坪 {{ house.totalPing }} 坪</span>
                                                <span v-if="house.buildingPing != null">建坪 {{ house.buildingPing }} 坪</span>
                                            </p>
                                            <p class="address">
                                                <i class="icon me-1">
                                                    <IconPin class="stroke-primary"/>
                                                </i> {{ house.address }}
                                            </p>
                                            <p class="address" v-if="house.communityName != ''">
                                                <i class="icon me-1">
                                                    <IconBuilding class="stroke-primary fill-primary"/>
                                                </i>
                                                <span v-if="house.communityUrl != ''"><a :href="house.communityUrl" target="_blank">{{ house.communityName }}</a></span>
                                                <span v-else>{{ house.communityName }}</span>
                                            </p>
                                        </div>
                                        <div class="uii-gup">
                                            <span class="tag-lbgreen me-2" v-if="house.category == 2">內政部</span>
                                            <a href="#" class="link-red_uii" data-bs-toggle="modal" :data-bs-target="house.category == 2 ? '#actualModal2' : '#actualModal'"
                                               @click="showModal(house)">查看完整資訊</a>
                                        </div>
                                    </div>
                                    <div class="price-group noborder px-0">
                                        <div class="price-wrapper">
                                            <div class="row gx-2">
                                                <div class="crd col-6">
                                                    <p class="prc mb-0"><span class="value">{{ house.totalPrice }}</span> <small>萬</small></p>
                                                    <div class="unit">總價</div>
                                                </div>
                                                <div class="crd col-6">
                                                    <p class="prc mb-0"><span class="value">{{ house.averagePrice }}</span> <small>萬/坪</small></p>
                                                    <div class="unit">單價</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav aria-label="Page navigation" v-html="paginate" v-if="mode == 2"></nav>
                    </div>
                    <div class="statement-info-footer statement">
                        <h3 class="mb-0">使用聲明</h3>
                        <p class="mb-0">本網站資料來源：好屋在線之成交資料及內政部實價登錄。</p>
                        <p class="mb-0">
                            自109年7月之後，內政部實價登錄的建物形態已整併為公寓、透天厝、大樓及華廈等四類，因此政府實價登錄提供的行情資訊，可能會含有店面、辦公、套房…等類型之建物行情。另當指定店面、辦公、套房..等建物形態，因內政部實價登錄未提供相關資訊，可能無法提供完整行情。
                            每一案件會因為房屋有無裝潢、屋齡、格局、景觀、交通、建材及離道路遠近、景氣波動等因素，而影響房屋之成交價格。
                        </p>
                        <p class="mb-0">
                            有車位之建坪單價計算方式，如能分算車位坪數及車位價格者，係以總坪數及總價扣除車位坪數及車位價格計算而得；如車位坪數併入總坪數無法分算者，係以總價除以總坪數計算而得，兩者相除所計算出的單價可能會有高低落差的狀況，建議僅供參考。
                            預售屋之建坪單價，係依賣方提供之房屋預定買賣契約書上記載坪數計算。車位有無登記坪數，應依個案確認。
                        </p>
                        <p class="mb-0">由於地圖定位誤差及每次輸入的條件或查詢介面不同，以同一地址查詢，可能產生參考之實價登錄一覽表部份不一致之結果。 本實價登錄僅供參考，不作其他用途。
                        </p>
                        <p class="mb-0">以上顯示的價格，可能因為房屋本身及周遭條件的差異，所以同一區域的房屋單價會有高低落差。</p>
                        <p class="mb-0">本網站提供之各種實價登錄表僅供參考，並不作為預測未來成交價格或單價走勢，實際市場交易狀況會依物件本身條件而有所差異。</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="onsale-section fafa-bg">
            <div class="container">
                <hr class="sm-hr">
                <div class="d-flex mb-4 align-items-center">
                    <h3 class="mb-0 onsale-tt"><span class="tt2 mb-0">共有<span class="text-danger mx-1">{{ sellTotalRecord }}</span>筆正在銷售中的物件</span>
                    </h3>
                </div>
                <div class="onsale-items">
                    <div class="row">
                        <div class="col-12 col-md-3" v-for="(item, index) in sellList">
                            <HouseCard
                                :siteName=siteName
                                :about=about
                                :item=item
                            ></HouseCard>
                        </div>
                    </div>
                    <nav aria-label="Page navigation" v-html="sellPaginate"></nav>
                </div>
            </div>
        </section>
        <div class="modal fade" id="mobileSearchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="width < 768">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title w-100 text-center" id="exampleModalLabel">篩選條件</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul id="accordionMobile" class="accordion_mobile accordion accordion-flush region-list">
                            <li class="item" role="presentation">
                                <button type="button" class="nav-link w-100 active" data-bs-toggle="collapse" data-bs-target="#condition1"
                                        aria-expanded="false" aria-controls="north" aria-selected="false">縣市
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedCity != 0">{{ checkedList.checkedCity }}</span>
                                        <span class="condition-text" v-else>不限</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition1" class="accordion-collapse collapse " data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[0]" @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[1]" @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[2]" @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[3]" @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition2"
                                        aria-expanded="false" aria-controls="central" aria-selected="false" ref="townList">區域
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedTown == ''">不限</span>
                                        <span class="condition-text" v-else>{{ checkedList.checkedTown }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition2" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(town, index) in townList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="town" :id="'town-' + index" :value="town" v-model="checkedList.checkedTown">
                                                    <label class="form-check-label" :for="'town-' + index" @click="changeTown()">{{ town }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition3"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">型態
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedHouseType.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedHouseType.length == 1">{{ checkedList.checkedHouseType[0] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedHouseType.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition3" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(houseType, index) in houseTypeList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="houseType[]" :id="'houseType-' + index" :value="index + 1"
                                                           v-model="checkedList.checkedHouseType" @click="changeChecked">
                                                    <label class="form-check-label" :for="'houseType-' + index">{{ houseType }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="3" type="button" data-bs-toggle="collapse" data-bs-target="#condition4"
                                        aria-expanded="false" aria-controls="east" aria-selected="false">總價
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedPrice.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedPrice.length == 1">{{ priceList[checkedList.checkedPrice[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedPrice.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition4" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(price, index) in priceList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index" :value="index + 1"
                                                           @click="changeChecked()" v-model="checkedList.checkedPrice">
                                                    <label class="form-check-label" :for="'price-' + index">{{ price }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="price-8" value="9" v-model="checkedList.checkedPrice">
                                                        <label class="form-check-label" for="price-8"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')" @keyup="changeChecked()"
                                                               v-model="checkedList.priceS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')" @keyup="changeChecked()"
                                                               v-model="checkedList.priceE">
                                                    </div>
                                                    <span class="px-2">萬</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition6"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">屋齡
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedAge.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedAge.length == 1">{{ ageList[checkedList.checkedAge[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedAge.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition6" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(age, index) in ageList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedList.checkedAge">
                                                    <label class="form-check-label" :for="'age-' + index">{{ age }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="age-6" value="6" v-model="checkedList.checkedAge">
                                                        <label class="form-check-label" for="age-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')" @keyup="changeChecked()"
                                                               v-model="checkedList.ageS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')" @keyup="changeChecked()"
                                                               v-model="checkedList.ageE">
                                                    </div>
                                                    <span class="px-2">年</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition5"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">格局
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedRoom.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedRoom.length == 1">{{ roomList[checkedList.checkedRoom[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedRoom.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition5" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(room, index) in roomList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="room" :id="'room-' + index" :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedList.checkedRoom">
                                                    <label class="form-check-label" :for="'room-' + index">{{ room }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="room-5" value="5" v-model="checkedList.checkedRoom">
                                                        <label class="form-check-label" for="room-5"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')" @keyup="changeChecked()"
                                                               v-model="checkedList.roomS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')" @keyup="changeChecked()"
                                                               v-model="checkedList.roomE">
                                                    </div>
                                                    <span class="px-2">房</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition7"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">樓層
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedFloor.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedFloor.length == 1">{{ floorList[checkedList.checkedFloor[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedFloor.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition7" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(floor, index) in floorList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'floor-' + index" :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedList.checkedFloor">
                                                    <label class="form-check-label" :for="'floor-' + index">{{ floor }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="floor-5" value="5" v-model="checkedList.checkedFloor">
                                                        <label class="form-check-label" for="floor-5"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('floor')" @keyup="changeChecked"
                                                               v-model="checkedList.floorS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('floor')" @keyup="changeChecked"
                                                               v-model="checkedList.floorE">
                                                    </div>
                                                    <span class="px-2">樓</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition9"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">建坪
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedBuildingPing.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedBuildingPing.length == 1">{{ buildingPingList[checkedList.checkedBuildingPing[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedBuildingPing.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition9" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(buildingPing, index) in buildingPingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'buildingPing-' + index" :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedList.checkedBuildingPing">
                                                    <label class="form-check-label" :for="'buildingPing-' + index">{{ buildingPing }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="buildingPing-6" value="6" v-model="checkedList.checkedBuildingPing">
                                                        <label class="form-check-label" for="buildingPing-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('buildingPing')" @keyup="changeChecked()"
                                                               v-model="checkedList.buildingPingS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('buildingPing')" @keyup="changeChecked()"
                                                               v-model="checkedList.buildingPingE">
                                                    </div>
                                                    <span class="px-2">坪</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition10"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">地坪
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedLandPing.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedLandPing.length == 1">{{
                                                landPingList[checkedList.checkedLandPing[0] - 1]
                                            }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedLandPing.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition10" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(landPing, index) in landPingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'landPing-' + index" :value="index + 1"
                                                           @click="changeChecked()" v-model="checkedList.checkedLandPing">
                                                    <label class="form-check-label" :for="'landPing-' + index">{{ landPing }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="landPing-6" value="6" v-model="checkedList.checkedLandPing">
                                                        <label class="form-check-label" for="landPing-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                               @keyup="changeChecked()" v-model="checkedList.landPingS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                               @keyup="changeChecked()" v-model="checkedList.landPingE">
                                                    </div>
                                                    <span class="px-2">坪</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition11"
                                        aria-expanded="false" aria-controls="south" aria-selected="false">車位
                                    <div class="acc-btn-right">
                                        <span class="condition-text">{{ parkingList[checkedList.checkedParking - 1] }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition11" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(parking, index) in parkingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :id="'parking-' + index" :value="index + 1"
                                                           @click="changeChecked()" v-model="checkedList.checkedParking">
                                                    <label class="form-check-label" :for="'parking-' + index">{{ parking }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer pt-1">
                        <div class="row g-2 w-100">
                            <div class="col-6">
                                <button class="btn btn-outline-secondary clear_result w-100" type="button" @click="clear">清空條件</button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary w-100 check_result" type="button" data-bs-dismiss="modal"
                                        @click="hideCollapse(); track('店頭-搜尋物件', '');">
                                    查看結果({{ totalRecord }})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade realPriceModal sharetoModal actualmodal" id="actualModal" tabindex="-1" aria-labelledby="actualModalLabel"
             aria-hidden="true">
            <div :class="'modal-dialog' + (this.width < 768 ? ' modal-fullscreen' : '')">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>實價登錄完整資訊</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="actual__slider" :style="imageMode == 1 ? '' : 'display:none;'">
                            <div class="ratio-actual-cover ratio">
                                <img :src="modalHouse.patternImg">
                            </div>
                        </div>
                        <div class="actual__slider" :style="imageMode == 2 ? '' : 'display:none;'">
                            <div class="for-wrapper">
                                <div class="slider-for">
                                    <div class="item" v-for="img in modalHouse.img">
                                        <div class="ratio-actual-cover ratio">
                                            <img :src="img">
                                        </div>
                                    </div>
                                </div>
                                <div class="number" v-if="modalHouse.img.length > 0">{{ currentSlide }} / {{ modalHouse.img.length }}</div>
                            </div>
                            <div class="slider-nav">
                                <div class="item" v-for="img in modalHouse.img">
                                    <div class="ratio-actual-nav ratio">
                                        <img :src="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="control-panel d-flex justify-content-center">
                            <a href="javascript:;" :class="'btn btn-option' + (imageMode == 1 ? ' active' : '')" @click="changeImageMode(1)"
                               v-if="modalHouse.patternImg != ''">
                                <i class="icon d-none d-md-inline-block">
                                    <IconStructure/>
                                </i>
                                格局圖
                            </a>
                            <a href="javascript:;" :class="'btn btn-option' + (imageMode == 2 ? ' active' : '')" @click="changeImageMode(2)" v-if="modalHouse.img.length > 0">
                                全部照片({{ modalHouse.img.length }})
                            </a>
                        </div>
                        <div class="cont">
                            <div class="cont-info-wrapper">
                                <p><span class="tt">成交年月</span> <span class="val">{{ modalHouse.transactionDate }}</span></p>
                                <p><span class="tt">物件名稱</span> <span class="val">{{ modalHouse.subject }}</span></p>
                                <p><span class="tt">地址</span> <span class="val">{{ modalHouse.city + modalHouse.town + modalHouse.address }}</span></p>
                                <p><span class="tt">型態</span> <span class="val">{{ modalHouse.buildingType }}</span></p>
                                <p><span class="tt">總價</span> <span class="val">{{ modalHouse.totalPrice }}萬</span></p>
                                <p><span class="tt">單價</span> <span class="val">{{ modalHouse.averagePrice }}萬</span></p>
                                <p><span class="tt">總坪</span> <span class="val">{{ modalHouse.totalPing }}坪</span></p>
                                <p><span class="tt">建坪</span> <span class="val">{{ modalHouse.buildingPing }}坪</span></p>
                                <p><span class="tt">地坪</span> <span class="val">{{ modalHouse.landPing }}坪</span></p>
                                <p><span class="tt">屋齡</span> <span class="val">{{ modalHouse.age }}年</span></p>
                                <p><span class="tt">樓層</span> <span class="val">{{ modalHouse.floor }}</span></p>
                                <p><span class="tt">格局</span> <span class="val">{{ modalHouse.pattern }}</span></p>
                                <p v-if="modalHouse.communityName != ''"><span class="tt">社區名稱</span> <span class="val">{{ modalHouse.communityName }}</span></p>
                                <div class="source">來源：公司</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade sharetoModal actualmodal" id="actualModal2" tabindex="-1" aria-labelledby="actualModal2Label" aria-hidden="true">
            <div :class="'modal-dialog' + (this.width < 768 ? ' modal-fullscreen' : '')">
                <!--            <div class="modal-dialog modal-dialog-centered">-->
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>實價登錄完整資訊</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="cont">
                            <div class="cont-info-wrapper">
                                <p><span class="tt">成交年月</span> <span class="val">{{ modalGov.transactionDate }}</span></p>
                                <p><span class="tt">地址</span> <span class="val">{{ modalGov.address }}</span></p>
                                <p><span class="tt">型態</span> <span class="val">{{ modalGov.buildingType }}</span></p>
                                <p><span class="tt">總價</span> <span class="val">{{ modalGov.totalPrice }}萬</span></p>
                                <p><span class="tt">單價</span> <span class="val">{{ modalGov.averagePrice }}萬</span></p>
                                <p><span class="tt">總坪</span> <span class="val">{{ modalGov.totalPing }}坪</span></p>
                                <p><span class="tt">地坪</span> <span class="val">{{ modalGov.landPing }}坪</span></p>
                                <p v-if="modalGov.isLand == 0"><span class="tt">屋齡</span> <span class="val">{{ modalGov.age }}年</span></p>
                                <p v-if="modalGov.isLand == 0"><span class="tt">樓層</span> <span class="val">{{ modalGov.floor }}</span></p>
                                <p><span class="tt">格局</span> <span class="val">{{ modalGov.pattern }}</span></p>
                                <p><span class="tt">備註</span> <span class="val">{{ modalGov.memo }}</span></p>
                                <div class="source">來源：內政部實價登錄</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import RealPriceMap from '@/components/RealPriceMap.vue'
import HouseCard from '@/components/HouseCard.vue'
import CommunityCard from '@/components/CommunityCard.vue'
import CommunityMap from '@/components/CommunityMap.vue'
import CommunityItem from '@/components/CommunityItem.vue'
import ArrRight from '@/components/icons/ArrRight.vue'
import ArrDown from '@/components/icons/ArrDown.vue'
import IconPin from '@/components/icons/IconPin.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconSortUp from '@/components/icons/IconSortUp.vue'
import IconSortDown from '@/components/icons/IconSortDown.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconGrid from '@/components/icons/IconGrid.vue'
import IconSort from '@/components/icons/IconSort.vue'
import IconInfo from '@/components/icons/IconInfo.vue'
import IconBuilding from '@/components/icons/IconBuilding.vue'
import IconStructure from '@/components/icons/IconStructure.vue'
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js'

import "leaflet/dist/leaflet.css"
import L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.markercluster";

export default {
    name: 'SellingPrice',
    components: {
        RealPriceMap,
        HouseCard,
        CommunityCard,
        CommunityMap,
        CommunityItem,
        ArrRight,
        ArrDown,
        IconPin,
        IconClose,
        IconSortUp,
        IconSearch,
        IconSortDown,
        IconMap,
        IconGrid,
        IconSort,
        IconInfo,
        IconBuilding,
        IconStructure
    },
    data() {
        return {
            siteName: '',
            meta: {},
            about: {},
            searchTagList: [],
            width: 0,
            currentPage: 0,
            image: {},
            imageMode: 2,

            // region: 0,
            regionList: [],
            cityList: [],
            cityIndex: 0,
            townUnlimited: 0,
            townList: [],
            houseTypeList: [],
            transactionPeriodList: [],
            priceList: [],
            ageList: [],
            roomList: [],
            averagePriceList: [],
            floorList: [],
            buildingPingList: [],
            landPingList: [],
            parkingList: [],
            orderByList: [],

            houseList: [],
            areaList: [],
            totalRecord: '',
            paginate: '',
            statistics: {
                information: [
                    {
                        monthS: '',
                        monthE: '',
                    },
                    {
                        monthS: '',
                        monthE: '',
                    },
                    {
                        monthS: '',
                        monthE: '',
                    },
                    {
                        monthS: '',
                        monthE: '',
                    },
                ],
                average: [
                    {},
                    {},
                    {},
                    {},
                ],
                count: [
                    {},
                    {},
                    {},
                    {},
                ],
            },

            checkedList: this.initChecked(),

            sellCurrentPage: 0,
            sellList: [],
            sellTotalRecord: '',
            sellPaginate: '',

            modalHouse: {
                img: []
            },
            modalGov: {},
            mode: 1,
            checkedRange: 3,
            checkedCategory: 0,

            currentSlide: 1,

            map: '',
            marker: '',
            divIcon: [],
            markerChecked: 0,

            leaflet: {
                zoom: 16,
                center: [22.612961, 120.304167],
                houseLatLng: [22.612961, 120.304167],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
                options: {
                    scrollWheelZoom: false,
                },
                icon: {
                    type: [
                        "/map_icon/pin-food.png",
                        "/map_icon/pin-bag.png",
                        "/map_icon/pin-forest.png",
                        "/map_icon/pin-bus.png",
                        "/map_icon/pin-money.png",
                    ],
                    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                    iconSize: [35, 45],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                },
                markerList: [],
            },
        }
    },
    methods: {
        setChart: function () {
            this.changeRange(this.checkedRange)
        },
        changeRange: function (range) {
            this.checkedRange = range;

            // var chart = echarts.init(document.getElementById('chartMain'));
            let chart = echarts.init(this.$refs.chart);
            // 指定图表的配置项和数据
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#888'
                        }
                    }
                },
                legend: {
                    data: ['成交件數', '平均單價'],
                    bottom: 10,
                    left: 'center',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.statistics.information[this.checkedRange].monthArray,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            fontSize: this.width < 767 ? 8 : 12,
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        min: this.statistics.information[this.checkedRange].intervalAverage[this.checkedCategory],
                        max: this.statistics.information[this.checkedRange].maxAverage[this.checkedCategory],
                        interval: this.statistics.information[this.checkedRange].intervalAverage[this.checkedCategory],
                        axisLabel: {
                            formatter: '{value} 萬',
                            fontSize: this.width < 767 ? 8 : 12,
                        }
                    },
                    {
                        type: 'value',
                        name: '',
                        min: this.statistics.information[this.checkedRange].intervalCount[this.checkedCategory],
                        max: this.statistics.information[this.checkedRange].maxCount[this.checkedCategory],
                        interval: this.statistics.information[this.checkedRange].intervalCount[this.checkedCategory],
                        axisLabel: {
                            formatter: '{value} 件',
                            fontSize: this.width < 767 ? 8 : 12,
                        }
                    },
                ],
                series: [
                    {
                        name: '平均單價',
                        type: 'line',
                        color: [
                            this.statistics.colorA,
                            this.statistics.colorB,
                            // '#DD4C27',
                            // '#759aa0',
                        ],
                        smooth: true,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 萬';
                            }
                        },
                        data: this.statistics.information[this.checkedRange].averageStatistics[this.checkedCategory]
                    },
                    {
                        name: '成交件數',
                        type: 'bar',
                        // 此系列自己的调色盘。
                        color: [
                            this.statistics.colorA,
                            this.statistics.colorB,
                            // 'rgba(221, 76, 39, 0.4)',
                            // '#759aa0',
                        ],
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 件';
                            }
                        },
                        data: this.statistics.information[this.checkedRange].countStatistics[this.checkedCategory]
                    }
                ]
            };
            chart.setOption(option);

            $(window).on('resize', function () {
                if (chart != null && chart != undefined) {
                    chart.resize();
                }
            });
        },
        changeCategory: function (category) {
            this.checkedCategory = category;
            this.setChart();
        },
        initChecked: function () {
            return {
                keyword: '',
                checkedCity: '台北市',
                checkedTown: '中正區',
                checkedHouseType: [],
                checkedTransactionPeriod: [],
                transactionPeriodS: '',
                transactionPeriodE: '',
                checkedPrice: [],
                priceS: '',
                priceE: '',
                checkedAge: [],
                ageS: '',
                ageE: '',
                checkedRoom: [],
                roomS: '',
                roomE: '',
                checkedAveragePrice: [],
                averagePriceS: '',
                averagePriceE: '',
                checkedFloor: [],
                floorS: '',
                floorE: '',
                checkedBuildingPing: [],
                buildingPingS: '',
                buildingPingE: '',
                checkedLandPing: [],
                landPingS: '',
                landPingE: '',
                checkedParking: 0,
                orderBy: 'transactionDate',
                sort: 1,
                showItem: [],
            }
        },
        changeMode: function (mode) {
            this.mode = mode;
        },
        showModal: function (house) {
            if (house.category == 2) {
                this.modalGov = house;
            } else {
                this.modalHouse = house;
            }
        },
        track: function (type, typeName) {
            return false;
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
            if (type == '店頭-搜尋物件') {
                setTimeout(() => {
                    $('.btn-primary').blur()
                }, 500);
            }
        },
        hideCollapse: function () {
            $('#mobileSearchModal .btn-close').click()
            // $('#accordionMobile button.active').click();
        },
        handleOpenSelect: function (num) {
            let dropOpen = document.querySelector('.dropdown-wrapper');
            for (let x = 1; x <= 7; x++) {
                if (x != num) {
                    $('.dropdown-' + x).removeClass('dropOpen');
                }
            }
            if (event) {
                event.currentTarget.parentNode.classList.toggle('dropOpen')
            }
        },
        getTown: function (city) {
            this.checkedList.checkedTown = '-';
            this.townList = [];

            setTimeout(() => {
                for (let x = 0; x < 4; x++) {
                    for (let y = 0; y < this.cityList[x].length; y++) {
                        this.cityList[x][y].active = '';
                        this.cityList[x][y].selected = '';
                        if (city.city == this.cityList[x][y].city) {
                            this.cityList[x][y].active = ' active';
                        }
                    }
                }
                this.townList = city.town;

                for (let x = 0; x < 4; x++) {
                    for (let y = 0; y < this.cityList[x].length; y++) {
                        this.cityList[x][y].active = '';
                        this.cityList[x][y].selected = '';
                        if (city.city == this.cityList[x][y].city) {
                            this.cityList[x][y].active = ' active';
                        }
                    }
                }

                this.townList = city.town;
                this.checkedList.checkedCity = city.city;
                this.checkCitySelected();
                this.checkTownUnlimited();
                if (this.width < 768) {
                    this.$refs.townList.click();
                }
            }, 1);

        },
        checkCitySelected: function () {
            let addon = 0;
            this.countSelected = 0;
            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].selected = '';
                    for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                        for (let index = 0; index < this.checkedList.checkedTown.length; index++) {
                            if (this.checkedList.checkedTown[index] == this.cityList[x][y].town[z] && this.cityList[x][y].selected != ' selected') {
                                if (this.cityList[x][y].active == '') {
                                    this.cityList[x][y].selected = ' selected';
                                    this.countSelected++;
                                } else {
                                    addon = 1;
                                }
                                break;
                            }
                        }
                    }
                }
            }
            this.countSelected += addon;
        },
        checkTownUnlimited: function () {
            let townListLength = this.townList.length;
            let checkedLength = 0;
            for (let x = 0; x < townListLength; x++) {
                for (let y = 0; y < this.checkedList.checkedTown.length; y++) {
                    if (this.townList[x] == this.checkedList.checkedTown[y]) {
                        checkedLength++;
                        break;
                    }
                }
            }
            this.townUnlimited = townListLength == checkedLength;
        },
        changeTownUnlimited: function () {
            setTimeout(() => {
                for (let x = 0; x < this.townList.length; x++) {
                    for (let y = 0; y < this.checkedList.checkedTown.length; y++) {
                        if (this.townList[x] == this.checkedList.checkedTown[y]) {
                            this.checkedList.checkedTown.splice(y, 1);
                            break;
                        }
                    }
                    if (this.townUnlimited == 1) {
                        this.checkedList.checkedTown.push(this.townList[x]);
                    }
                }
                this.search(0);
            }, 50);
        },
        changeTown: function () {
            setTimeout(() => {
                this.checkTownUnlimited();
                this.checkCitySelected();
                this.search(0);
            }, 50);
        },
        changeCommunityType: function () {
            this.search(0);
        },
        checkFocusOther: function (type) {
            let checked, index;
            switch (type) {
                case 'transactionPeriod':
                    checked = this.checkedList.checkedTransactionPeriod;
                    index = 6;
                    break;
                case 'price':
                    checked = this.checkedList.checkedPrice;
                    index = 9;
                    break;
                case 'age':
                    checked = this.checkedList.checkedAge;
                    index = 6;
                    break;
                case 'room':
                    checked = this.checkedList.checkedRoom;
                    index = 5;
                    break;
                case 'averagePrice':
                    checked = this.checkedList.checkedAveragePrice;
                    index = 6;
                    break;
                case 'floor':
                    checked = this.checkedList.checkedFloor;
                    index = 5;
                    break;
                case 'buildingPing':
                    checked = this.checkedList.checkedBuildingPing;
                    index = 6;
                    break;
                case 'landPing':
                    checked = this.checkedList.checkedLandPing;
                    index = 6;
                    break;
            }
            for (let x = 0; x < checked.length; x++) {
                if (checked[x] == index) {
                    return false;
                }
            }
            checked.push(index);
        },
        changeChecked: function () {
            this.search(0);
        },
        searchTag: function (key, value) {
            this.searchTagList.push({
                key: key,
                value: value
            })
        },
        deleteTag: function (index) {
            let tag = this.searchTagList[index];
            switch (tag.key) {
                case 'keyword':
                    this.checkedList.keyword = '';
                    break;
                case 'city':
                    let cityName = tag.value.split('全區')[0];
                    for (let x = 0; x < 4; x++) {
                        for (let y = 0; y < this.cityList[x].length; y++) {
                            if (this.cityList[x][y].city != cityName) {
                                continue;
                            }
                            for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                                for (let index = 0; index < this.checkedList.checkedTown.length; index++) {
                                    if (this.checkedList.checkedTown[index] == this.cityList[x][y].town[z]) {
                                        this.checkedList.checkedTown.splice(index, 1);
                                    }
                                }
                            }
                        }
                    }
                    this.checkTownUnlimited();
                    break;
                case 'town':
                    this.checkedList.checkedTown = '';
                    break;
                case 'houseType':
                    for (let i = 0; i < this.checkedList.checkedHouseType.length; i++) {
                        if (this.checkedList.checkedHouseType[i] == tag.index) {
                            this.checkedList.checkedHouseType.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'transactionPeriod':
                    for (let i = 0; i < this.checkedList.checkedTransactionPeriod.length; i++) {
                        if (this.checkedList.checkedTransactionPeriod[i] == tag.index) {
                            if (tag.index == 6) {
                                this.transactionPeriodS = '';
                                this.transactionPeriodE = '';
                            }
                            this.checkedList.checkedTransactionPeriod.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'price':
                    for (let i = 0; i < this.checkedList.checkedPrice.length; i++) {
                        if (this.checkedList.checkedPrice[i] == tag.index) {
                            if (tag.index == 9) {
                                this.priceS = '';
                                this.priceE = '';
                            }
                            this.checkedList.checkedPrice.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'age':
                    for (let i = 0; i < this.checkedList.checkedAge.length; i++) {
                        if (this.checkedList.checkedAge[i] == tag.index) {
                            if (tag.index == 6) {
                                this.ageS = '';
                                this.ageE = '';
                            }
                            this.checkedList.checkedAge.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'room':
                    for (let i = 0; i < this.checkedList.checkedRoom.length; i++) {
                        if (this.checkedList.checkedRoom[i] == tag.index) {
                            if (tag.index == 5) {
                                this.roomS = '';
                                this.roomE = '';
                            }
                            this.checkedList.checkedRoom.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'averagePrice':
                    for (let i = 0; i < this.checkedList.checkedAveragePrice.length; i++) {
                        if (this.checkedList.checkedAveragePrice[i] == tag.index) {
                            if (tag.index == 6) {
                                this.averagePriceS = '';
                                this.averagePriceE = '';
                            }
                            this.checkedList.checkedAveragePrice.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'floor':
                    for (let i = 0; i < this.checkedList.checkedFloor.length; i++) {
                        if (this.checkedList.checkedFloor[i] == tag.index) {
                            if (tag.index == 5) {
                                this.floorS = '';
                                this.floorE = '';
                            }
                            this.checkedList.checkedFloor.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'buildingPing':
                    for (let i = 0; i < this.checkedList.checkedBuildingPing.length; i++) {
                        if (this.checkedList.checkedBuildingPing[i] == tag.index) {
                            if (tag.index == 6) {
                                this.buildingPingS = '';
                                this.buildingPingE = '';
                            }
                            this.checkedList.checkedBuildingPing.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'landPing':
                    for (let i = 0; i < this.checkedList.checkedLandPing.length; i++) {
                        if (this.checkedList.checkedLandPing[i] == tag.index) {
                            if (tag.index == 6) {
                                this.landPingS = '';
                                this.landPingE = '';
                            }
                            this.checkedList.checkedLandPing.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'parking':
                    this.checkedList.checkedParking = 0;
                    break;
            }
            this.search(0);
        },
        realPriceSort: function (type) {
            if (type == this.checkedList.orderBy) {
                if (this.checkedList.sort == 1) {
                    this.checkedList.sort = 2;
                } else {
                    this.checkedList.sort = 1;
                }
            } else {
                this.checkedList.orderBy = type;
                this.checkedList.sort = 1;
            }
            this.search(0);
        },
        changeOrderBy: function (index) {
            this.checkedList.orderBy = this.orderByList[index].orderBy;
            this.checkedList.sort = this.orderByList[index].sort;
            this.search(0);
        },
        changeImageMode: function (imageMode) {
            this.imageMode = imageMode;
        },
        search: function (page, allow) {
            let pageCount = 16;
            if (this.currentPage == page && page != 0 && allow == undefined) {
                return false;
            }
            setTimeout(() => {
                this.searchTagList = [];
                if (this.checkedList.keyword != '') {
                    this.searchTagList.push({
                        key: 'keyword',
                        value: this.checkedList.keyword,
                    });
                }
                if (this.checkedList.checkedTown != '') {
                    this.searchTagList.push({
                        key: 'town',
                        value: this.checkedList.checkedTown,
                    });
                }
                if (this.checkedList.checkedHouseType.length > 0) {
                    this.searchTagAppend(this.houseTypeList, this.checkedList.checkedHouseType, 'houseType', -1, '', '', '');
                }
                if (this.checkedList.checkedTransactionPeriod.length > 0) {
                    this.searchTagAppend(this.transactionPeriodList, this.checkedList.checkedTransactionPeriod, 'transactionPeriod', 6, this.checkedList.transactionPeriodS, this.checkedList.transactionPeriodE, '年');
                }
                if (this.checkedList.checkedPrice.length > 0) {
                    this.searchTagAppend(this.priceList, this.checkedList.checkedPrice, 'price', 9, this.checkedList.priceS, this.checkedList.priceE, '萬');
                }
                if (this.checkedList.checkedAge.length > 0) {
                    this.searchTagAppend(this.ageList, this.checkedList.checkedAge, 'age', 6, this.checkedList.ageS, this.checkedList.ageE, '年');
                }
                if (this.checkedList.checkedRoom.length > 0) {
                    this.searchTagAppend(this.roomList, this.checkedList.checkedRoom, 'room', 5, this.checkedList.roomS, this.checkedList.roomE, '房');
                }
                if (this.checkedList.checkedAveragePrice.length > 0) {
                    this.searchTagAppend(this.averagePriceList, this.checkedList.checkedAveragePrice, 'averagePrice', 6, this.checkedList.averagePriceS, this.checkedList.averagePriceE, '萬');
                }
                if (this.checkedList.checkedFloor.length > 0) {
                    this.searchTagAppend(this.floorList, this.checkedList.checkedFloor, 'floor', 5, this.checkedList.floorS, this.checkedList.floorE, '樓');
                }
                if (this.checkedList.checkedBuildingPing.length > 0) {
                    this.searchTagAppend(this.buildingPingList, this.checkedList.checkedBuildingPing, 'buildingPing', 6, this.checkedList.buildingPingS, this.checkedList.buildingPingE, '坪');
                }
                if (this.checkedList.checkedLandPing.length > 0) {
                    this.searchTagAppend(this.landPingList, this.checkedList.checkedLandPing, 'landPing', 6, this.checkedList.landPingS, this.checkedList.landPingE, '坪');
                }
                if (this.checkedList.checkedParking != 0) {
                    let checkedParking = this.checkedList.checkedParking - 1;
                    for (let i = 0; i < this.parkingList.length; i++) {
                        if (checkedParking == i) {
                            this.searchTagList.push({
                                key: 'parking',
                                value: this.parkingList[i] + '車位',
                            });
                            break;
                        }
                    }
                }
                if (this.width > 767) {
                    $.each($('.btn-tab'), function (k, v) {
                        if (!$(this).hasClass('collapsed')) {
                            $(this).click();
                        }
                    });
                    pageCount = 16
                } else {
                    pageCount = 6;
                }
                if (page == 0) {
                    page = 1;
                }
                if (this.mode == 1) {
                    page = 1;
                    pageCount = 100000;
                }
                this.currentPage = page;
                let param = {
                    city: this.checkedList.checkedCity,
                    town: this.checkedList.checkedTown,
                    houseType: this.checkedList.checkedHouseType,
                    transactionPeriod: this.checkedList.checkedTransactionPeriod,
                    price: this.checkedList.checkedPrice,
                    priceS: this.checkedList.priceS,
                    priceE: this.checkedList.priceE,
                    age: this.checkedList.checkedAge,
                    ageS: this.checkedList.ageS,
                    ageE: this.checkedList.ageE,
                    room: this.checkedList.checkedRoom,
                    roomS: this.checkedList.roomS,
                    roomE: this.checkedList.roomE,
                    averagePrice: this.checkedList.checkedAveragePrice,
                    averagePriceS: this.checkedList.averagePriceS,
                    averagePriceE: this.checkedList.averagePriceE,
                    floor: this.checkedList.checkedFloor,
                    floorS: this.checkedList.floorS,
                    floorE: this.checkedList.floorE,
                    buildingPing: this.checkedList.checkedBuildingPing,
                    buildingPingS: this.checkedList.buildingPingS,
                    buildingPingE: this.checkedList.buildingPingE,
                    landPing: this.checkedList.checkedLandPing,
                    landPingS: this.checkedList.landPingS,
                    landPingE: this.checkedList.landPingE,
                    parking: this.checkedList.parking,
                    keyword: this.checkedList.keyword,
                    orderBy: this.checkedList.orderBy,
                    sort: this.checkedList.sort,
                    page: page,
                    pageCount: pageCount,
                }
                this.axios.post('/api/real_price/search', param)
                    .then(response => (
                        this.houseList = response.data.houseList,
                            this.areaList = response.data.areaList,
                            this.paginate = response.data.paginate,
                            this.totalRecord = response.data.totalRecord,
                            this.sellList = response.data.sell.houseList,
                            this.sellPaginate = response.data.sell.paginate,
                            this.sellTotalRecord = response.data.sell.totalRecord,
                            this.statistics = response.data.statistics,
                            window.history.pushState('page2', '實價登錄', '/real_price' + response.data.query),
                            this.resetMarkerList()
                    ))
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.setChart();
                        });
                        // always executed
                    });
            }, 200);
        },
        searchHouse: function (page) {
            let pageCount = 16;
            if (this.width > 767) {
                pageCount = 16
                pageCount = 8
            } else {
                pageCount = 8;
            }
            let param = {
                town: this.checkedList.checkedTown,
                houseType: this.checkedList.checkedHouseType,
                page: page,
                pageCount: pageCount,
            }
            this.axios.post('/api/houses/search', param)
                .then(response => (
                    this.sellList = response.data.houseList,
                        this.sellPaginate = response.data.paginate,
                        this.sellTotalRecord = response.data.totalRecord
                ))
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        searchTagAppend: function (list, checkedList, key, otherIndex, dataS, dataE, wording) {
            for (let i = 0; i < checkedList.length; i++) {
                if (checkedList[i] == otherIndex) {
                    if (dataS != '' && dataE != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataS + '~' + dataE + wording,
                            index: checkedList[i],
                        });
                    } else if (dataS != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataS + wording + '以上',
                            index: checkedList[i],
                        });
                    } else if (dataE != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataE + wording + '以下',
                            index: checkedList[i],
                        });
                    }
                } else {
                    this.searchTagList.push({
                        key: key,
                        value: list[checkedList[i] - 1],
                        index: checkedList[i],
                    });
                }
            }
        },
        clear: function () {
            this.checkedList = this.initChecked();
            this.search(0);
            setTimeout(() => {
                $('.clear-all').blur()
            }, 500)
        },
        resetMarkerList: function () {
            // return false;
            this.leaflet.markerList = [];
            this.marker = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    var clusterSize = "small";
                    if (cluster.getChildCount() < 10000) {
                        clusterSize = "small";
                    }
                    return new L.DivIcon({
                        html: '<div><span>' + cluster.getChildCount() + '</span></div>',
                        className: 'marker-cluster marker-cluster-' + clusterSize,
                        iconSize: new L.Point(40, 40)
                    });
                }
            });
            this.marker.on('clusterclick', (event) => {
                // this.map.setZoom(18);
                this.checkedList.showItem = [];
                let markerList = event.layer.getAllChildMarkers();
                for (let x = 0; x < markerList.length; x++) {
                    console.log(markerList[x].options)
                    console.log(markerList[x].options.id)
                    this.checkedList.showItem.push(markerList[x].options.id);
                }
                setTimeout(() => {
                    this.map.setView(event.latlng, 17);
                }, 100)
            });
            for (let x = 0; x < this.houseList.length; x++) {
                let item = this.houseList[x];
                let marker = {
                    type: 0,
                    name: item.subject,
                    latLng: [item.lat, item.lng],
                };
                this.leaflet.markerList.push(marker);

                let markerItem = L.marker([item.lat, item.lng], {
                    id: item.id,
                    title: item.subject,
                    icon: L.icon({
                        iconUrl: '/map_icon/pin.png',
                        iconSize: [21, 25],
                    })
                }).on('click', this.markerOnClick)
                markerItem.bindPopup(item.subject, {
                    offset: [0, 2],
                    closeButton: false,
                });
                this.marker.addLayer(markerItem);
                if (x == 0) {
                    this.map.setView([item.lat, item.lng], this.leaflet.zoom)
                }
            }
            this.map.addLayer(this.marker);
        },
        markerOnClick: function (event) {
            this.markerChecked = event.sourceTarget.options.id;
            setTimeout(() => {
                $('.map-list-group').scrollTop($('.map-list-group').scrollTop() + $('.checked').position().top);
            }, 100);
        },
        zoomIn: function (event) {
            // this.map.setZoom(18);
            setTimeout(() => {
                this.map.setView(event.latlng, 17);
            }, 100)
        },
        start: function () {
            let house = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let url = new URL(window.location);
            let keyword = params.get("keyword");
            if (keyword != null) {
                this.checkedList.keyword = keyword;
            }
            let city = params.get("city");
            if (city != null) {
                this.checkedList.checkedCity = city;
                for (let region = 0; region < 4; region++) {
                    $.each(this.cityList[region], function (k, v) {
                        if (v.city == city) {
                            house.getTown(v);
                        }
                    })
                }
            }
            let town = params.get("town");
            if (town != null) {
                this.checkedList.checkedTown = town;
            }
            let houseType = url.searchParams.getAll("houseType[]");
            if (houseType != null) {
                this.checkedList.checkedHouseType = houseType;
            }
            let price = url.searchParams.getAll("price[]");
            if (price != null) {
                this.checkedList.checkedPrice = price;
                let priceS = params.get("priceS");
                if (priceS != null) {
                    this.checkedList.priceS = priceS;
                }
                let priceE = params.get("priceE");
                if (priceE != null) {
                    this.checkedList.priceE = priceE;
                }
            }
            let age = url.searchParams.getAll("age[]");
            if (age != null) {
                this.checkedList.checkedAge = age;
                let ageS = params.get("ageS");
                if (ageS != null) {
                    this.checkedList.ageS = ageS;
                }
                let ageE = params.get("ageE");
                if (ageE != null) {
                    this.checkedList.ageE = ageE;
                }
            }
            let room = url.searchParams.getAll("room[]");
            if (room != null) {
                this.checkedList.checkedRoom = room;
                let roomS = params.get("roomS");
                if (roomS != null) {
                    this.checkedList.roomS = roomS;
                }
                let roomE = params.get("roomE");
                if (roomE != null) {
                    this.checkedList.roomE = roomE;
                }
            }
            let averagePrice = url.searchParams.getAll("averagePrice[]");
            if (averagePrice != null) {
                this.checkedList.checkedAveragePrice = averagePrice;
                let averagePriceS = params.get("averagePriceS");
                if (averagePriceS != null) {
                    this.checkedList.averagePriceS = averagePriceS;
                }
                let averagePriceE = params.get("averagePriceE");
                if (averagePriceE != null) {
                    this.checkedList.averagePriceE = averagePriceE;
                }
            }
            let floor = url.searchParams.getAll("floor[]");
            if (floor != null) {
                this.checkedList.checkedFloor = floor;
                let floorS = params.get("floorS");
                if (floorS != null) {
                    this.checkedList.floorS = floorS;
                }
                let floorE = params.get("floorE");
                if (floorE != null) {
                    this.checkedList.floorE = floorE;
                }
            }
            let buildingPing = url.searchParams.getAll("buildingPing[]");
            if (buildingPing != null) {
                this.checkedList.checkedBuildingPing = buildingPing;
                let buildingPingS = params.get("buildingPingS");
                if (buildingPingS != null) {
                    this.checkedList.buildingPingS = buildingPingS;
                }
                let buildingPingE = params.get("buildingPingE");
                if (buildingPingE != null) {
                    this.checkedList.buildingPingE = buildingPingE;
                }
            }
            let landPing = url.searchParams.getAll("landPing[]");
            if (landPing != null) {
                this.checkedList.checkedLandPing = landPing;
                let landPingS = params.get("landPingS");
                if (landPingS != null) {
                    this.checkedList.landPingS = landPingS;
                }
                let landPingE = params.get("landPingE");
                if (landPingE != null) {
                    this.checkedList.landPingE = landPingE;
                }
            }
            let parking = params.get("parking");
            if (parking != null) {
                this.checkedList.parking = parking;
            }
            let orderBy = params.get("orderBy");
            if (orderBy != null) {
                this.checkedList.orderBy = orderBy;
            }
            let page = params.get("page");
            if (page != null) {
                this.currentPage = page;
                house.search(this.currentPage, true)
            } else {
                house.search(this.currentPage)
            }
        }
    },
    mounted() {
        let house = this;
        new Tooltip(document.body, {
            selector: "[data-toggle='tooltip']",
        })
        this.width = $(window).width();
        this.axios.get('/api/real_price/init')
            .then(response => (
                this.siteName = response.data.siteName,
                    this.meta = response.data.meta,
                    this.about = response.data.about,
                    this.image = response.data.image,
                    this.regionList = response.data.regionList,
                    this.cityList = response.data.cityList,
                    this.houseTypeList = response.data.houseTypeList,
                    this.transactionPeriodList = response.data.transactionPeriodList,
                    this.priceList = response.data.priceList,
                    this.ageList = response.data.ageList,
                    this.roomList = response.data.roomList,
                    this.averagePriceList = response.data.averagePriceList,
                    this.floorList = response.data.floorList,
                    this.buildingPingList = response.data.buildingPingList,
                    this.landPingList = response.data.landPingList,
                    this.parkingList = response.data.parkingList,
                    this.orderByList = response.data.orderByList,
                    window.setTimeout(function () {
                        $("#communityWiqoeuu").slick({
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            responsive: [
                                {
                                    breakpoint: 9999,
                                    settings: "unslick"
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        infinite: true,
                                        dots: false,
                                        centerMode: true,
                                        arrows: true,
                                        variableWidth: true,
                                    }
                                },
                            ]
                        })
                    }, 3000),
                    document.title = this.meta.title,
                    $("meta[property='og:title']").attr("content", this.meta.title),
                    document.getElementsByTagName('meta')["description"].content = this.meta.description,
                    $("meta[property='og:description']").attr("content", this.meta.description),
                    this.start()
            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
        $('.flex-column').click(function () {
            house.townList = [];
        });

        $("body").on("click", ".region-list button", function (event) {
            house.region = $(this).attr('region');
        });

        $("body").on("click", ".real-price-list .page-link", function (event) {
            let page = $(this).attr('page');
            house.search(page);

            setTimeout(() => {
                window.scrollTo(0, document.querySelector("#totalRecord").offsetTop);
            }, 500)
        });

        $("body").on("click", ".onsale-section .page-link", function (event) {
            let page = $(this).attr('page');
            house.searchHouse(page);
        });

        $(document).click(function (event) {
            if ($(event.target).prop('tagName') != 'path' &&
                $(event.target).prop('tagName') != 'svg' &&
                $(event.target).attr('class') != 'icon' &&
                $(event.target).attr('class') != 'btn btn-select' &&
                $(event.target).attr('class') != 'btn btn-select selected' &&
                $(event.target).closest('.select-dropdown').length == 0) {
                $('.dropOpen').removeClass('dropOpen');
            }
        });

        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });

        this.map = L.map('map').setView(this.leaflet.center, this.leaflet.zoom);
        console.log(this.map.getBounds())

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.map.on('zoomend', () => {
            if (this.map.getZoom() == 13) {
                this.map.removeLayer(this.marker);
                for (let x = 0; x < this.areaList.length; x++) {
                    let area = this.areaList[x];
                    let areaIcon = L.divIcon({
                        html: '<div><div>' + area.town + '<br>' + area.count + '</div></div>',
                        className: 'marker-cluster-area',
                        iconSize: new L.Point(70, 70),
                    });
                    this.divIcon.push(L.marker(area.center, {icon: areaIcon}).on('click', this.zoomIn).addTo(this.map));
                }
            } else if (this.map.getZoom() > 13 && this.divIcon.length > 0) {
                for (let x = 0; x < this.divIcon.length; x++) {
                    this.map.removeLayer(this.divIcon[x]);
                }
                this.divIcon = [];
                this.resetMarkerList()
            }
        });

        var myModalEl = document.getElementById('actualModal')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            $('.slider-for').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                asNavFor: '.slider-nav'
            });
            $('.slider-nav').slick({
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: false,
                focusOnSelect: true,
                variableWidth: true
            });
        })
        $('.slider-for').on('afterChange', function (event, slick, currentSlide) {
            house.currentSlide = currentSlide + 1;
        });
    }
}
</script>
