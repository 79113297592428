<template>
    <div class="list-content-parent">
        <div :class="'actual-mpcard' + (house.id == markerChecked ? ' checked' : '')"
             :style="showItem.length == 0 || showItem.indexOf(house.id) != -1 ? '' : 'display: none;'">
            <h3 class="title text-truncate">{{ house.subject }}</h3>
            <div class="info-container">
                <div class="infos-group">
                    <p class="infos">
                        <span class="me-3" v-if="house.age != -1">{{ house.age }}年</span>
                        <span class="me-3">{{ house.floor }}樓</span>
                        <span class="">{{ house.buildingType }}</span>
                    </p>
                    <p class="infos">
                        <span class="me-3">總坪 {{ house.totalPing }} 坪</span>
                        <span v-if="house.buildingPing != null">建坪 {{ house.buildingPing }} 坪</span>
                    </p>
                    <p class="address text-truncate">
                        <i class="icon me-1">
                            <IconPin class="stroke-primary"/>
                        </i> {{ house.address }}
                    </p>
                    <p class="address text-truncate" v-if="house.communityName != ''">
                        <i class="icon me-1">
                            <IconBuilding class="stroke-primary fill-primary"/>
                        </i>
                        <span v-if="house.communityUrl != ''"><a :href="house.communityUrl" target="_blank">{{ house.communityName }}</a></span>
                        <span v-else>{{ house.communityName }}</span>
                    </p>
                </div>
                <div class="uii-gup d-flex justify-content-end align-items-center">
                    <span class="tag-lbgreen me-2" v-if="house.category == 2">內政部</span>
                    <a href="javascript:;" class="link-red_uii me-3" data-bs-toggle="modal"
                       :data-bs-target="house.category == 2 ? '#actualModal2' : '#actualModal'" @click="showModal(house)">查看完整資訊</a>
                </div>
            </div>
            <div class="price-group no-photo">
                <div class="row gx-3">
                    <div class="crd col-6">
                        <div class="unit">總價</div>
                        <p class="prc mb-0"><span class="value">{{ house.totalPrice }}</span> <small>萬</small></p>
                    </div>
                    <div class="crd col-6">
                        <div class="unit">單價</div>
                        <p class="prc mb-0"><span class="value">{{ house.averagePrice }}</span> <small>萬/坪</small></p>
                    </div>
                </div>
            </div>
            <div class="info-photo d-none d-md-block">
                <div class="row gx-2">
                    <div class="col-4" v-for="(img, index) in house.img">
                        <div class="ratio ratio-1x1" v-if="index < 3">
                            <img :src="img">
                        </div>
                    </div>
                    <div class="col-4" v-if="house.patternImg != '' && house.img.length < 3">
                        <div class="ratio ratio-1x1">
                            <img :src="house.patternImg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'RealPriceMapList',
    props: {
        house: Object,
        markerChecked: Number,
        showItem: Array,
    },
    components: {
        IconPin,
    },
    methods: {
        showModal: function (house) {
            this.$parent.showModal(house);
        },
        track: function (type, typeName) {
            return false;
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
}
</script>

