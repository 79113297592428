<template>
    <router-link :to="community.url" :target="width < 768 ? '_self' : '_blank'" @click="track('店頭-看物件', community.title)">
        <div class="list-item">
            <div class="img">
                <div class="img-ratio-community">
                    <img :src="community.img[0]" :alt="community.title" class="w-100" v-if="community.img[0] != ''">
                </div>
            </div>
            <div class="list-body">
                <div class="list-heading">
                    <h3 class="text-truncate">{{ community.title }}</h3>
                    <div class="sale-tag ms-3">{{ community.salesItem }} <span class="small">筆待售</span></div>
                </div>
                <div class="body-group">
                    <div class="body-left">
                        <div class="info-group">
                            <span class="me-4">{{ community.household }}戶</span>
                            <span>{{ community.age }}年</span>
                        </div>
                        <div class="info-group">
                            <span class="me-4">{{ community.ping }}坪</span>
                            <span>{{ community.floor }}F</span>
                        </div>
                        <div class="address-wrapper mt-auto">
                            <p class="address">
                                <i class="icon">
                                    <IconPin/>
                                </i> {{ community.address }}
                            </p>
                        </div>
                    </div>
                    <div class="body-right">
                        <div class="box-card">
                            <div class="small-title">最新成交價</div>
                            <div class="value"><span class="price">{{ community.closingPrice }}</span> <span class="unit">萬/坪</span></div>
                        </div>
                        <div class="box-card">
                            <div class="small-title">近一年成交數</div>
                            <div class="value">{{ community.yearClinch }} <span class="unit">筆</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'CommunityListItem',
    data() {
        return {
            width: 0
        };
    },
    props: {
        community: Object,
    },
    components: {
        IconPin,
    },
    methods: {
        track: function (type, typeName) {
            return false;
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
        });
    }
}
</script>

